body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header3 {
  background: #fff;
  width: 100%;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  top: 0;
}
.header3 .home-page {
  padding: 0 24px;
}
.header3-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header3-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header3-logo a {
  display: block;
}
.header3-menu {
  float: right;
}
.header3-menu .ant-menu-horizontal {
  border-bottom: none;
}
.header3-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
}
.header3-menu .ant-menu a {
  display: block;
}
.header3-item-block {
  padding: 0 8px;
}
.header3-item-block > * {
  display: inline-block;
}
.header3-item .ant-menu-sub .ant-menu-item,
.header3-item-child .ant-menu-sub .ant-menu-item,
.header3-menu .ant-menu-sub .ant-menu-item,
.header3-item .ant-menu-inline .ant-menu-item,
.header3-item-child .ant-menu-inline .ant-menu-item,
.header3-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header3-item .item-sub-item,
.header3-item-child .item-sub-item,
.header3-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header3-item .item-image,
.header3-item-child .item-image,
.header3-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header3-item .item-title,
.header3-item-child .item-title,
.header3-menu .item-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 46px;
}
.header3-item .item-content,
.header3-item-child .item-content,
.header3-menu .item-content {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header3-logo {
    z-index: 101;
  }
  .header3.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header3-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
  }
  .header3-menu li {
    padding: 0 24px;
  }
  .header3-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header3-menu .item-sub-item {
    padding: 8px 0;
  }
  .header3-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header3-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header3-mobile-menu :first-child {
    margin-top: 0;
  }
  .header3 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header3 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header3 .open {
    height: auto;
  }
  .header3 .open .header3-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header3 .open .header3-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header3 .open .header3-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header3 .open > .header3-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header3-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner5 {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%);
  overflow: hidden;
}
.banner5 .banner5-page {
  padding: 1px 0 0;
}
.banner5-title {
  font-size: 48px;
  line-height: 1.5;
  color: #5f9bf1;
  margin-bottom: 8px;
}
.banner5-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner5-explain {
  color: #333;
  font-size: 24px;
  margin-bottom: 24px;
}
.banner5-content {
  font-size: 18px;
  color: #666;
  margin-bottom: 48px;
}
.banner5-button-wrapper .banner5-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
}
.banner5-button-wrapper .banner5-button:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner5-image {
  overflow: hidden;
  width: 55%;
  max-width: 710px;
  position: absolute;
  top: 15%;
  right: 24px;
}
@media screen and (max-width: 767px) {
  .banner5 {
    min-height: 500px;
  }
  .banner5 .banner5-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner5-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner5-title {
    font-size: 32px;
  }
  .banner5-explain {
    font-size: 18px;
  }
  .banner5-content {
    font-size: 14px;
  }
  .banner5-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.content1-wrapper {
  height: 360px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content6-wrapper {
  min-height: 720px;
}
.content6-wrapper .content6 {
  height: 100%;
  display: flex;
  align-items: center;
}
.content6-wrapper .content6-text {
  min-height: 424px;
}
.content6-wrapper .content6-text > *.queue-anim-leaving {
  position: relative !important;
}
.content6-wrapper .content6-text .title-h1 {
  position: relative;
  margin: 0 0 16px;
  text-align: left;
  font-size: 2em;
}
.content6-wrapper .content6-text .title-content {
  position: relative;
  margin-bottom: 64px;
  text-align: left;
}
.content6-wrapper .content6-text ul {
  position: relative;
  display: inline-block;
}
.content6-wrapper .content6-text ul li {
  margin-bottom: 24px;
}
.content6-wrapper .content6-text ul li .content6-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
}
.content6-wrapper .content6-text ul li .content6-title,
.content6-wrapper .content6-text ul li .content6-content {
  margin-left: 45px;
}
.content6-wrapper .content6-text ul li .content6-title {
  font-size: 14px;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .content6-wrapper {
    height: 860px;
    overflow: hidden;
  }
  .content6-wrapper .content6 {
    display: block;
  }
  .content6-wrapper .content6-img,
  .content6-wrapper .content6-text {
    display: block;
    width: 100%;
  }
  .content6-wrapper .content6-text > h1,
  .content6-wrapper .content6-text > p {
    text-align: center;
  }
  .content6-wrapper .content6-text > h1 {
    margin: 56px auto 16px;
  }
  .content6-wrapper .content6-text p {
    margin-bottom: 32px;
  }
  .content6-wrapper .content6-img {
    margin-top: 20px;
  }
}
.content9-wrapper {
  padding-bottom: 64px;
}
.content9-wrapper.home-page-wrapper {
  overflow: initial;
}
.content9-wrapper .content9 {
  min-height: 800px;
  padding: 64px 0 0;
}
.content9-wrapper .timeline {
  position: relative;
}
.content9-wrapper .timeline:before {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 0;
  height: calc(100% - 108px);
  margin: 34px 0;
  border-left: 2px #ebedf0 dashed;
}
.content9-wrapper .block-wrapper {
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  position: relative;
  margin-bottom: 70px;
  min-height: 160px;
}
.content9-wrapper .block-wrapper:last-child {
  margin-bottom: 0;
}
.content9-wrapper .image-wrapper,
.content9-wrapper .text-wrapper {
  width: 50%;
}
.content9-wrapper .image-wrapper {
  text-align: center;
}
.content9-wrapper .image-wrapper .block-img,
.content9-wrapper .image-wrapper .name-wrapper {
  float: right;
  clear: both;
  width: 262px;
  margin: auto;
}
.content9-wrapper .image-wrapper .block-img {
  height: 98px;
}
.content9-wrapper .image-wrapper .block-img img {
  height: 100%;
}
.content9-wrapper .image-wrapper .name-wrapper {
  margin: 16px 0 0;
}
.content9-wrapper .image-wrapper .name-wrapper .block-name {
  font-size: 14px;
  margin-bottom: 4px;
}
.content9-wrapper .image-wrapper .name-wrapper .block-post {
  font-size: 12px;
  color: #697b8c;
}
.content9-wrapper .text-wrapper {
  padding-left: 40px;
}
.content9-wrapper .text-wrapper .block-time {
  font-size: 12px;
  color: #697b8c;
  line-height: 18px;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-title {
  margin: 8px 0 12px;
  font-size: 14px;
  position: relative;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-icon {
  position: absolute;
  left: -40px;
  -webkit-transform: translateX(calc(-50% + 1px));
          transform: translateX(calc(-50% + 1px));
}
.content9-wrapper .text-wrapper .block-content {
  width: 300px;
  color: #314659;
  font-size: 12px;
  min-height: 18px;
}
@media screen and (max-width: 767px) {
  .content9-wrapper {
    padding-bottom: 0;
  }
  .content9-wrapper .content9 {
    padding: 64px 24px;
  }
  .content9-wrapper .timeline:before {
    left: 0;
    height: 100%;
    margin: 0;
  }
  .content9-wrapper .block-wrapper {
    display: block;
    min-height: 100px;
    margin-bottom: 54px;
  }
  .content9-wrapper .image-wrapper,
  .content9-wrapper .text-wrapper {
    width: 100%;
  }
  .content9-wrapper .image-wrapper .block-img {
    display: none;
  }
  .content9-wrapper .image-wrapper .name-wrapper {
    text-align: left;
    float: left;
    width: 100%;
    padding-left: 40px;
    margin: auto;
  }
  .content9-wrapper .image-wrapper .block-name,
  .content9-wrapper .image-wrapper .block-post {
    display: inline-block;
  }
  .content9-wrapper .image-wrapper .block-name {
    margin-right: 8px;
  }
  .content9-wrapper .text-wrapper .block-content {
    display: none;
  }
  .content9-wrapper .text-wrapper .block-title {
    font-size: 16px;
  }
}
.feature8-wrapper {
  min-height: 680px;
  margin: 0 auto;
  overflow: hidden;
  background-color: rgba(253, 253, 253, 0.3);
  background-image: linear-gradient(360deg, rgba(193, 218, 255, 0.3) 0%, rgba(253, 253, 253, 0.3) 80%);
}
.feature8-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.feature8-title-wrapper {
  text-align: center;
  margin-bottom: 40px;
}
.feature8-title-h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
}
.feature8-title-content {
  margin-top: 16px;
}
.feature8-carousel {
  width: calc(100% + 72px);
  margin-left: -36px;
}
.feature8-carousel-title {
  font-size: 20px;
  text-align: center;
}
.feature8-carousel-title-block {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.45s;
  padding: 0 40px;
  position: relative;
  cursor: pointer;
}
.feature8-carousel-title-block.active {
  color: rgba(0, 0, 0, 0.85);
  cursor: auto;
}
.feature8-carousel-title-block::before {
  display: block;
  content: '';
  width: 1px;
  height: 70%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: #d8d8d8;
}
.feature8-carousel-title-block:last-child::before {
  display: none;
}
.feature8-block {
  margin-top: 48px;
}
.feature8-block-wrapper {
  margin-top: 32px;
}
.feature8-block-row {
  padding: 0 36px;
}
.feature8-block-col {
  text-align: center;
  margin: 36px 0;
  position: relative;
}
.feature8-block-col:last-child .feature8-block-arrow {
  display: none;
}
.feature8-block-child {
  padding: 36px 24px 24px;
  border-radius: 6px;
  height: 100%;
  transition: box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature8-block-child:hover {
  background-color: #f6f9ff;
  box-shadow: 0 12px 36px rgba(100, 100, 136, 0.15);
}
.feature8-block-child:hover .feature8-block-content {
  opacity: 1;
}
.feature8-block-image {
  display: inline-block;
}
.feature8-block-title {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 16px;
}
.feature8-block-content {
  opacity: 1;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 36px;
  text-align: left;
  transition: opacity 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature8-block-arrow {
  position: absolute;
  right: 0;
  width: 16px;
  top: 80px;
}
.feature8-button {
  text-align: center;
}
.feature8-button-wrapper {
  margin-top: 16px;
}
.feature8-button .ant-btn {
  padding: 8px 40px;
  border-radius: 20px;
  line-height: 1.5;
  height: 40px;
  border: none;
  color: #fff;
  background-color: #3c89f6;
  background-image: linear-gradient(135deg, #82b5ff 0%, #3c89f6 100%);
  box-shadow: 0 9px 22px rgba(95, 155, 241, 0.35);
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform, box-shadow;
}
.feature8-button .ant-btn:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0 14px 26px rgba(95, 155, 241, 0.4);
}
@media screen and (max-width: 767px) {
  .feature8-wrapper {
    padding-bottom: 0;
    min-height: 1540px;
  }
  .feature8-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px 64px;
  }
  .feature8-carousel-title {
    font-size: 16px;
  }
  .feature8-carousel-title-block {
    padding: 0 8px;
  }
  .feature8-block-child {
    width: 80%;
    margin: auto;
    background-color: #f6f9ff;
    box-shadow: 0 12px 36px rgba(100, 100, 136, 0.15);
  }
  .feature8-block-content {
    text-align: center;
  }
  .feature8-block-arrow {
    bottom: -40px;
    top: auto;
    left: 0;
    right: 0;
    margin: auto;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}
.feature6-wrapper {
  min-height: 360px;
  margin: 0 auto;
  overflow: hidden;
}
.feature6-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.feature6-title {
  display: inline-block;
}
.feature6-title-wrapper {
  text-align: center;
  margin-bottom: 48px;
}
.feature6-title-text {
  display: inline-block;
  margin: 0 72px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.feature6-title-text.active {
  color: #1890ff;
}
.feature6-title-bar {
  height: 6px;
  background: #1890ff;
  width: 20%;
  margin: auto;
  display: block;
}
.feature6-title-bar-wrapper {
  position: relative;
  margin-top: 8px;
  transition: left 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature6-number,
.feature6-text {
  text-align: center;
}
.feature6-number {
  font-size: 48px;
  color: #1890ff;
  font-weight: 600;
}
.feature6-number-wrapper {
  text-align: center;
}
.feature6-text {
  font-size: 20px;
}
.feature6-unit {
  font-size: 16px;
  color: #1890ff;
}
.feature6 .ant-carousel .slick-dots-bottom {
  bottom: -24px;
}
.feature6 .ant-carousel .slick-dots li button {
  background: rgba(24, 144, 255, 0.3);
}
.feature6 .ant-carousel .slick-dots li.slick-active button {
  background: #1890ff;
}
@media screen and (max-width: 767px) {
  .feature6-wrapper {
    padding-bottom: 0;
    min-height: 580px;
  }
  .feature6-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px 64px;
  }
  .feature6-title-text {
    margin: 0 14px;
  }
  .feature6-title-bar {
    width: 40%;
  }
  .feature6-number-wrapper {
    margin-bottom: 16px;
  }
}
.footer0-wrapper {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
#Nav3_0.jzih1dpqqrg-editor_css {
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;
}
.ant-menu-item > .item-sub-item > .jzj81c9wabh-editor_css {
  float: none;
  margin: 4px 0px 0px;
}
.ant-menu > .ant-menu-item > .jzj8295azrs-editor_css {
  padding: 0;
}
#Nav3_0 .home-page > .l7k7h5bcvf-editor_css {
  display: none;
}
#Feature8_0 .feature8-block > .feature8-button-wrapper > .l7k8km1pjfl-editor_css {
  display: none;
}
#Feature1_0 .ant-row > .ant-col > .l7k8t4a867e-editor_css {
  width: 100%;
}
#Feature8_0 .ant-col > .feature8-block-child > .l7k9hiilfwe-editor_css {
  text-align: center;
}
#Feature8_0 .ant-col > .feature8-block-child > .l7k9hb10efj-editor_css {
  text-align: center;
}
#Feature8_0 .ant-col > .feature8-block-child > .l7k9ic2cs7d-editor_css {
  text-align: center;
}
#Feature8_0 .ant-col > .feature8-block-child > .l7k9j704nie-editor_css {
  text-align: center;
}
#Banner5_0 .home-page > .l7k7ina3kv-editor_css {
  width: 40%;
}
#Feature6_0 .ant-row > .ant-col > .l7k9yz9hc9e-editor_css {
  font-size: 16px;
  padding: 0px 8px;
}
#Feature6_0 div > .ant-row > .l7k9yi24uy-editor_css {
  padding: 0px 0 0px 0px;
}
#Feature6_0 .ant-row > .ant-col > .l7k9yhg0zum-editor_css {
  font-size: 16px;
  padding: 0px 8px 0px 0px;
}
#Feature6_0 .ant-row > .ant-col > .l7k9z2qyptm-editor_css {
  font-size: 16px;
  padding: 0px 8px;
}
#Feature6_0 .ant-row > .ant-col > .l7k9z74lz6l-editor_css {
  font-size: 16px;
  padding: 0px 0px 0px 8px;
}
#Feature6_0 .ant-row > .ant-col > .l7ka4g3ze6o-editor_css {
  color: #59bba8;
}
#Feature6_0 .ant-row > .ant-col > .l7k9wwz8la-editor_css {
  display: inline-block;
  color: #59bba8;
}
#Feature6_0 .ant-row > .ant-col > .l7ka4p5ggl-editor_css {
  color: #59bba8;
}
#Feature6_0 .ant-row > .ant-col > .l7k9xialitf-editor_css {
  display: inline-block;
  color: #59bba8;
}
#Feature6_0 .ant-row > .ant-col > .l7ka4yzo1m8-editor_css {
  color: #59bba8;
}
#Feature6_0 .ant-row > .ant-col > .l7k9xv7e2vh-editor_css {
  display: inline-block;
  color: #59bba8;
}
#Feature6_0 .ant-row > .ant-col > .l7ka5axwzqi-editor_css {
  color: #59bba8;
}
#Feature6_0 .feature6-title-wrapper > .feature6-title > .l7ka3ak2bep-editor_css {
  display: none;
  color: #59bba8;
}
#Feature6_0 .feature6-title-wrapper > .feature6-title > .l7ka2wooqn-editor_css {
  font-size: 32px;
  color: #59bba8;
}
#Feature1_0 .l7ka74e9j9k-editor_css {
  margin: 64px auto auto;
}
#Banner5_0 .home-page > .banner5-title-wrapper > .l7k7hyorxb-editor_css {
  font-size: 36px;
  color: #195c84;
}
#Feature1_0 .ant-row > .ant-col > .l7kaekan3mo-editor_css {
  color: #195c84;
}
#Feature1_0.l7k8x10j2df-editor_css {
  height: auto;
}
#Feature4_0 .l7k977bogpi-editor_css {
  height: auto;
}
#Content9_0 .block-wrapper > .text-wrapper > .l7kaml2al4n-editor_css {
  display: none;
}
#Content9_0 .block-wrapper > .text-wrapper > .l7kaouuvxsl-editor_css {
  display: none;
}
#Content9_0 .block-wrapper > .text-wrapper > .l7kapffk9f-editor_css {
  display: none;
}
#Content9_0 .image-wrapper > .name-wrapper > .l7kaqo3waf-editor_css {
  display: none;
}
#Content9_0 .image-wrapper > .name-wrapper > .l7kaqwjwikk-editor_css {
  display: none;
}
#Content9_0 .image-wrapper > .name-wrapper > .l7kaqycjo9-editor_css {
  display: none;
}
#Content9_0 .image-wrapper > .name-wrapper > .l7kar0qx4h-editor_css {
  display: none;
}
#Content9_0 .image-wrapper > .name-wrapper > .l7kar2uo2wf-editor_css {
  display: none;
}
#Content9_0 .image-wrapper > .name-wrapper > .l7kar4hzb2-editor_css {
  display: none;
}
#Content9_0 .home-page > .title-wrapper > .l7kamhgokzl-editor_css {
  color: #195c84;
}
#Content9_0 .block-wrapper > .image-wrapper > .l7kasz3m4y-editor_css {
  width: 144px;
  height: 72px;
}
#Content9_0 .block-wrapper > .image-wrapper > .l7kau94ihwj-editor_css {
  width: 144px;
  height: 72px;
}
#Content9_0 .block-wrapper > .image-wrapper > .l7kavm8eqzp-editor_css {
  width: 144px;
  height: 72px;
}
#Feature4_0 .ant-row > .l7k8wgbqe6-editor_css {
  max-width: 80%;
}
#Feature8_0 .home-page > .feature8-title-wrapper > .l7k8kt5kte-editor_css {
  color: #195c84;
}
#Feature8_0 .ant-row > .ant-col > .l7kbvmg2klc-editor_css {
  align-items: flex-start;
}
#Feature8_0 .ant-col > .feature8-block-child > .l7kbg54zw6o-editor_css {
  width: 48px;
  height: 48px;
}
#Content9_0 .block-wrapper > .text-wrapper > .l7kclt2wup-editor_css {
  display: none;
}
#Nav3_0 .home-page > .jzjgnya1gmn-editor_css {
  width: 400px;
}
#Feature4_0 .ant-col > .title-wrapper > .l7kaer7mw37-editor_css {
  font-size: 32px;
  color: #195c84;
}

